// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-toolbar.ql-snow {
  background: #1d2125;
  border: 1px solid rgba(166, 197, 226, 0.16);
  border-radius: 4px;
}

.ql-container {
  min-height: 300px;
  background: #282e33;
  border-radius: 4px;
}

.ql-container.ql-snow {
  border: 1px solid rgba(166, 197, 226, 0.16);
}

.ql-snow .ql-stroke {
  stroke: #9fadbc;
}

.ql-snow .ql-picker {
  color: #9fadbc;
}

.ql-editor {
  color: #8c9bab;
  min-height: 500px;
}

.ticket-html-text {
  font-size: 14px;
  font-weight: normal;
  color: #9fadbc;
}

.ticket-html-text p {
  font-size: 14px;
  font-weight: normal;
  color: #9fadbc;
}

.mention {
  background: none;
  border: none;
  height: auto;
  margin-right: auto;
  padding: unset;
  width: auto;
  font-weight: 500;
  color: #25bafa;
}

.ql-mention-list-container {
  background-color: rgba(34, 39, 43, 1);
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4), 0 1px 3px rgba(0, 0, 0, 0.3);
  overflow: auto;
  height: 250px;
}

.ql-mention-list-item {
  font-family: Inter, sans-serif;
  font-size: 16px;
  color: rgba(140, 155, 171, 1);
}

.ql-mention-list-item.selected {
  background-color: rgb(20 23 25);
}
`, "",{"version":3,"sources":["webpack://./src/components/formlib/CustomRichText.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,2CAA2C;EAC3C,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,qCAAqC;EACrC,YAAY;EACZ,kBAAkB;EAClB,sEAAsE;EACtE,cAAc;EACd,aAAa;AACf;;AAEA;EACE,8BAA8B;EAC9B,eAAe;EACf,6BAA6B;AAC/B;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".ql-toolbar.ql-snow {\n  background: #1d2125;\n  border: 1px solid rgba(166, 197, 226, 0.16);\n  border-radius: 4px;\n}\n\n.ql-container {\n  min-height: 300px;\n  background: #282e33;\n  border-radius: 4px;\n}\n\n.ql-container.ql-snow {\n  border: 1px solid rgba(166, 197, 226, 0.16);\n}\n\n.ql-snow .ql-stroke {\n  stroke: #9fadbc;\n}\n\n.ql-snow .ql-picker {\n  color: #9fadbc;\n}\n\n.ql-editor {\n  color: #8c9bab;\n  min-height: 500px;\n}\n\n.ticket-html-text {\n  font-size: 14px;\n  font-weight: normal;\n  color: #9fadbc;\n}\n\n.ticket-html-text p {\n  font-size: 14px;\n  font-weight: normal;\n  color: #9fadbc;\n}\n\n.mention {\n  background: none;\n  border: none;\n  height: auto;\n  margin-right: auto;\n  padding: unset;\n  width: auto;\n  font-weight: 500;\n  color: #25bafa;\n}\n\n.ql-mention-list-container {\n  background-color: rgba(34, 39, 43, 1);\n  border: none;\n  border-radius: 8px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4), 0 1px 3px rgba(0, 0, 0, 0.3);\n  overflow: auto;\n  height: 250px;\n}\n\n.ql-mention-list-item {\n  font-family: Inter, sans-serif;\n  font-size: 16px;\n  color: rgba(140, 155, 171, 1);\n}\n\n.ql-mention-list-item.selected {\n  background-color: rgb(20 23 25);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
