import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
  ChangeEvent,
} from "react";
import { GoogleMap, Libraries, useJsApiLoader } from "@react-google-maps/api";
import Box from "@mui/material/Box";
import { WORK_FLOW_BASE_URL } from "../../../api/Constant";
import {
  fetchKmlData,
  KMLresponseType,
  kmlUploadApi,
} from "../../../api/wizardsApi/Common";
import useAvailableWidth from "../../../utils/useAvailableWidth";
import useAvailableHeight from "../../../utils/useAvailableHeight";
import ErrorModal from "../../../components/formlib/modal/ErrorModal";
import { LoaderContext, LoaderContextType } from "../../../layouts/appSidebar";
import { ReactComponent as NotAvialableIcon } from "../../../assets/images/woNotFound.svg";
import MediumTypography from "../../../components/formlib/MediumTypography";
import { handleError } from "../../../utils/commonFunctions";
import { ReactComponent as Upload } from "../../../assets/images/UploadIcon.svg";

interface UploadKmlProps {
  assetGroupId: number;
  assetGroupName: string;
  isMenuOpen: boolean;
  // parentCall: () => void;
}

// To fix type missmatch issue
type Library =
  | "core"
  | "maps"
  | "places"
  | "geocoding"
  | "routes"
  | "marker"
  | "geometry"
  | "elevation"
  | "streetView"
  | "journeySharing"
  | "drawing"
  | "visualization";

const libraries: Libraries = ["drawing"];

type SelectionMode = "select" | "deselect" | null;

const dataUrl = "./initialJson.geojson";
const apiKey = "AIzaSyA9e7q3S_JVIT8Tz16ZjWD3EFO03XGGwJo";

// Map container style - Full width & height

// Center coordinates for India, zoom should be set to 5
const center = {
  lat: 20.5937,
  lng: 78.9629,
};

// Map view type
const mapType = "HYBRID"; // "ROADMAP" | "TERRAIN" | "SATELLITE" | "HYBRID"

// Map display styles currently not working, might need to enable this feature on map console
const mapStyles: google.maps.MapTypeStyle[] = [
  {
    featureType: "all",
    elementType: "all",
    stylers: [
      { saturation: -10 }, // reduce saturation
      { lightness: 10 }, // increase lightness
    ],
  },
];

// Style Mappings
const STYLE_MAPPING = {
  strokeColor: "strokeClr",
  strokeOpacity: "strokeOpac",
  strokeWeight: "strokeWt",
  fillColor: "fillColor",
  fillOpacity: "fillOpac",
};

// Default styles
const DEFAULT_STYLE = {
  strokeColor: "#808080",
  strokeOpacity: 1,
  strokeWeight: 2,
  fillColor: "#808080",
  fillOpacity: 0,
};

const STATUS_COLORS = {
  PLANNED: "#A3F000", // Green
  PLANNED_TODAY: "#D9DF17", // Yellow
  ACTUAL: "#FF0000", // Red
  EXTRA: "#3AA63C", // Dark Green
};

const UploadKmlScreen: React.FC<UploadKmlProps> = ({
  assetGroupId,
  assetGroupName,
  isMenuOpen,
}) => {
  const [map, setMap] = useState<google.maps.Map>();
  const [data, setData] = useState<any>();
  const [polygons, setPolygons] = useState<Array<google.maps.Polygon>>([]);

  const originalStylesRef = useRef<Map<number, any>>(new Map());
  const mapContainerRef = useRef<HTMLElement>();
  const customControlsRef = useRef<HTMLDivElement>(null);
  const availableWidth = useAvailableWidth(540);
  const availableWidth1 = useAvailableWidth(200);
  const availableHeight = useAvailableHeight(250);
  const availableHeight1 = useAvailableHeight(200);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [errorDesc, setErrorDesc] = useState<string>("");
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);

  const mapContainerStyle = {
    width: isMenuOpen ? availableWidth : availableWidth1,
    height: availableHeight,
  };

  // Load required scripts
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    libraries: libraries as Library[], // Ensure the drawing library is loaded
  });

  // Map load callback
  const onMapLoad = useCallback((map: google.maps.Map) => {
    map.setOptions({
      styles: mapStyles, // custom map style array
      mapTypeId: google.maps.MapTypeId[mapType],
    });
    setMap(map);
  }, []);

  // Map tiles loaded callback
  const onTilesLoaded = useCallback(() => {
    if (customControlsRef.current) {
      customControlsRef.current.style.display = "block";
    }
  }, []);

  const fetchData = async () => {
    const url = `${WORK_FLOW_BASE_URL}${assetGroupId}`;

    // if (WORK_FLOW_BASE_URL) {
    try {
      const response = await fetchKmlData<KMLresponseType>(url, "en");
      if (typeof response.message === "string" && response.message !== "") {
        const responseData = await JSON.parse(response.message);
        setData(responseData);
        // dataRef.current = JSON.parse(response.message);
      }
    } catch (error) {
      console.error("Error fetching data file:", error);
    }
    // }
    console.error("fetching data file:", data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Drawing polygons from GeoJson data
  useEffect(() => {
    if (!map || !data) return;

    const features = data.features;

    // Bounds to encompass all the polygons -  this will be the viewport of the map
    const bounds = new google.maps.LatLngBounds();

    const newPolygons = features.map((feature: any) => {
      const coordinates = feature.geometry.coordinates[0][0].map(
        (coord: any) => {
          // Extend the bounds to include each polygon's bounds
          bounds.extend(new google.maps.LatLng(coord[1], coord[0]));
          return {
            lng: coord[0],
            lat: coord[1],
          };
        }
      );

      const properties = feature.properties;

      // Create a style object based on the properties
      const style = {
        strokeColor:
          properties[STYLE_MAPPING.strokeColor] || DEFAULT_STYLE.strokeColor,
        strokeOpacity:
          properties[STYLE_MAPPING.strokeOpacity] ||
          DEFAULT_STYLE.strokeOpacity,
        strokeWeight:
          properties[STYLE_MAPPING.strokeWeight] || DEFAULT_STYLE.strokeWeight,
        fillColor:
          properties[STYLE_MAPPING.fillColor] || DEFAULT_STYLE.fillColor,
        fillOpacity:
          properties[STYLE_MAPPING.fillOpacity] || DEFAULT_STYLE.fillOpacity,
      };

      // Generate a unique ID for the polygon
      // const polygonId = feature.id;
      const polygonId = properties.string;

      const polygonsData = new google.maps.Polygon({
        paths: coordinates,
        ...style,
        zIndex: polygonId ? 1 : 0,
      });

      polygonsData.set("polygonId", polygonId);

      // Store the polygon's original styles with its ID
      originalStylesRef.current.set(polygonId, style);

      // Add event listener on polygon hover to show info window
      polygonsData.addListener("mouseover", () => {
        const stringId = properties.string;
        if (stringId) {
          const middleCoords = polygonsData
            .getPath()
            .getArray()
            .slice(0, 2)
            .reduce(
              (acc, coord) => ({
                lng: acc.lng + coord.lng(),
                lat: acc.lat + coord.lat(),
              }),
              { lng: 0, lat: 0 }
            );

          const infoWindow = new google.maps.InfoWindow({
            content: stringId,
            position: {
              lng: middleCoords.lng / 2,
              lat: middleCoords.lat / 2,
            },
          });

          infoWindow.open(map);
          polygonsData.addListener("mouseout", () => infoWindow.close());
        }
      });

      polygonsData.setMap(map);

      return polygonsData;
    });

    // Check if bounds have been extended; if not, use indiaBounds
    if (bounds.isEmpty()) {
      // Bounds to show all India
      const indiaBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(6.5546079, 68.1113787), // southwest corner of India
        new google.maps.LatLng(35.6745457, 97.402561) // northeast corner of India
      );
      map.fitBounds(indiaBounds);
    } else {
      map.fitBounds(bounds);
    }

    setPolygons(newPolygons);
  }, [map, data]);

  const browseFiles = () => {
    inputFileRef.current?.click();
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (!file) {
      console.error("Invalid file or no file selected.");
      return;
    }

    toggleLoader(true);
    try {
      const response = await kmlUploadApi(file, "en", assetGroupId);
      fetchData();
      // setData(response); // Update the data state with new GeoJSON
      toggleLoader(false);

      // parentCall(); // Callback to parent if necessary
      // fetchData();
    } catch (err) {
      toggleLoader(false);
      handleError(err, setErrorDesc);
      setOpenErrorModal(true);
    }
  };

  useEffect(() => {
    const mapContainer = mapContainerRef.current;

    const disableScroll = () => {
      window.addEventListener("wheel", preventScroll, { passive: false });
    };

    const enableScroll = () => {
      window.removeEventListener("wheel", preventScroll);
    };

    const preventScroll = (e: WheelEvent) => {
      e.preventDefault();
    };

    mapContainer?.addEventListener("mouseenter", disableScroll);
    mapContainer?.addEventListener("mouseleave", enableScroll);

    return () => {
      // Cleanup listeners when the component unmounts
      mapContainer?.removeEventListener("mouseenter", disableScroll);
      mapContainer?.removeEventListener("mouseleave", enableScroll);
    };
  }, [mapContainerRef.current]);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ maxWidth: "100%" }}>
      {openErrorModal && (
        <ErrorModal
          descriptionText={errorDesc}
          open={openErrorModal}
          handleClose={() => {
            setOpenErrorModal(false);
          }}
          onPositiveClick={() => {
            setOpenErrorModal(false);
          }}
        />
      )}
      <Box className="flex__justify__space-between mb-md">
        <MediumTypography
          label={`${assetGroupName} Details`}
          fontSize="20px"
          fontWeight={600}
        />
        <Box
          className="flex__ cursor__pointer"
          sx={{
            padding: "8px 12px",
            borderRadius: "4px",
            border: "1px solid rgba(166, 197, 226, 0.16)",
            marginRight: "8px",
            backgroundColor: "#2B3033",
            justifyContent: "flex-end",
          }}
          onClick={browseFiles}
        >
          <input
            ref={inputFileRef}
            type="file"
            accept=".geojson"
            style={{ display: "none", height: "36px" }}
            onChange={handleFileChange}
          />
          <Upload />
          <MediumTypography
            labelId="UploadKml.text"
            defaultLabel="Upload KML"
            className="ml-xs"
          />
        </Box>
      </Box>
      <Box ref={mapContainerRef}>
        <Box style={{ width: "100%" }}>
          {/* {data && ( */}
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            onLoad={onMapLoad}
            onTilesLoaded={onTilesLoaded}
            zoom={5}
            options={{
              fullscreenControl: false,
              zoomControl: false,
            }}
          ></GoogleMap>
          {/* )} */}
        </Box>
      </Box>
      {!data && !map && (
        <Box
          className="position__relative"
          sx={{
            display: "flex", // Flex container
            flexDirection: "column", // Stack items vertically
            justifyContent: "center", // Center items vertically
            alignItems: "center", // Center items horizontally
            textAlign: "center", // Optional: Center the text
            // top: "50%",
            height: availableHeight1,
          }}
        >
          <NotAvialableIcon width={`50px`} height={`50px`} />
          <MediumTypography
            className="noWOTitle"
            labelId="NoKml.text"
            defaultLabel="Upload a KML file"
          />
        </Box>
      )}
    </Box>
  );
};

export default UploadKmlScreen;
