import React, { useEffect, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "quill-mention/dist/quill.mention.css";
import "quill-mention/autoregister"; // Automatically registers the mention module

// Define types for the mention items
interface MentionItem {
  id: number;
  value: string;
  [key: string]: any;
}

const defaultModules = {
  toolbar: [
    [{ size: [] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};

const defaultFormats = [
  "size",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
];

interface QuillMentionEditorProps {
  value?: string;
  onChange?: (content: string) => void;
  modules?: any;
  formats?: string[];
  readOnly?: boolean;
  placeholder?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  enableMentions?: boolean;
  mentionList?: MentionItem[]; // Add mentionList as a prop
  hashList?: MentionItem[]; // Add hashList as a prop
  quillRef?: React.RefObject<Quill>;
}

const QuillMentionEditor: React.FC<QuillMentionEditorProps> = ({
  value = "",
  onChange = () => {},
  modules = defaultModules,
  formats = defaultFormats,
  readOnly = false,
  placeholder,
  onBlur = () => {},
  onFocus = () => {},
  enableMentions = false,
  mentionList = [],
  hashList = [],
  quillRef = null,
}) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const localQuillRef = useRef<Quill | null>(null);

  useEffect(() => {
    if (quillRef?.current) {
      localQuillRef.current = quillRef.current;
    }
  }, [quillRef]);

  useEffect(() => {
    if (editorRef.current && !localQuillRef.current) {
      // Initialize Quill editor
      localQuillRef.current = new Quill(editorRef.current, {
        theme: "snow",
        modules: {
          ...modules,
          mention: !enableMentions
            ? undefined
            : {
                allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/, // Allow specific characters in mentions
                mentionDenotationChars: ["@", "#"], // Triggers for @ and # mentions
                source: function (
                  searchTerm: string,
                  renderList: Function,
                  mentionChar: string
                ) {
                  let values: MentionItem[];

                  // Choose the appropriate data based on the mention character
                  if (mentionChar === "@") {
                    values = mentionList;
                  } else {
                    values = hashList;
                  }

                  // If no search term, show all values
                  if (searchTerm.length === 0) {
                    renderList(values, searchTerm);
                  } else {
                    // Filter and show only matching values
                    const matches = values.filter((item) =>
                      item.value
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    );
                    renderList(matches, searchTerm);
                  }
                },
              },
        },
        // formats,
        readOnly,
        placeholder,
      });

      // Set initial content
      localQuillRef.current.root.innerHTML = value;

      // Handle text change
      localQuillRef.current.on("text-change", () => {
        onChange(localQuillRef.current!.root.innerHTML);
      });

      // Handle focus and blur events
      if (onFocus || onBlur) {
        localQuillRef.current.on("selection-change", (range) => {
          if (range === null) {
            onBlur();
          } else {
            onFocus();
          }
        });
      }
    }
  }, [
    editorRef,
    localQuillRef,
    value,
    onChange,
    modules,
    readOnly,
    placeholder,
    mentionList,
    hashList,
    onFocus,
    onBlur,
  ]);

  return (
    <div className="editor-ref-container">
      <div className="editor-ref-box" ref={editorRef}></div>
    </div>
  );
};

export default QuillMentionEditor;
